import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/source-sans-pro";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import PlausibleProvider from "next-plausible";
import { type AppType } from "next/app";
import { theme } from "~/theme";
import { api } from "~/utils/api";

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  return (
    <PlausibleProvider domain="skatgpt.dk">
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <Component {...pageProps} />
        </ChakraProvider>
      </SessionProvider>
    </PlausibleProvider>
  );
};

export default api.withTRPC(MyApp);
