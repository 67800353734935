export const colors = {
  blue: {
    50: "#E8EEFD",
    100: "#D5E1FB",
    200: "#A6C0F7",
    300: "#7CA2F3",
    400: "#4D81EF",
    500: "#2463EB",
    600: "#124AC4",
    700: "#0E3996",
    800: "#092562",
    900: "#051333",
  },
  yellow: {
    50: "#FDFDE7",
    100: "#FCFCD4",
    200: "#F9F8A9",
    300: "#F7F57E",
    400: "#F4F14E",
    500: "#F1EC24",
    600: "#CECB0D",
    700: "#9E9C0A",
    800: "#696807",
    900: "#353403",
  },
};
