import { extendTheme } from "@chakra-ui/react";
import { colors } from "~/theme/colors";

export const theme = extendTheme({
  colors,
  fonts: {
    heading: '"Source Sans Pro", sans-serif',
    body: '"Source Sans Pro", sans-serif',
  },
  styles: {
    global: {
      "html, body": {
        bg: "white",
        h: "100%",
      },
      "#__next": {
        h: "100%",
      },
    },
  },
  components: {
    Container: {
      baseStyle: {
        maxW: "container.xl",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "medium",
      },
      defaultProps: {
        colorScheme: "blue",
      },
      variants: {
        menu: {
          px: 0,
          justifyContent: "flex-start",
          fontWeight: "bold",
          textAlign: "left",
          _hover: {
            color: "blue.400",
          },
        },
      },
    },
  },
});
